import {
    http
} from '@/http/axios.js'


// 列表
export function getList(params) {
    return http({
        url: `/company/course/notes/list`,
        method: 'get',
        params
    })
}

// 列表
export function getAllMemberList(params) {
    return http({
        url: `/company/member/getAll`,
        method: 'get',
        params
    })
}

// 状态编辑
export function changeStatus(params) {
    return http({
        url: `/company/course/notes/changeStatus`,
        method: 'post',
        params: params
    })
}

export function remove(params) {
    return http({
        url: `/company/course/notes/delete`,
        method: 'post',
        params: params
    })
}

export function addCourse(params) {
    return http({
        url: `/company/course/notes/add`,
        method: 'post',
        params: params
    })
}

export function editCourse(data) {
    return http({
        url: `/company/course/notes/edit`,
        method: 'post',
        data
    })
}

export function detail(id) {
    return http({
        url: `/company/course/notes/detail`,
        method: 'get',
        params: {id : id}
    })
}
